import rtState, { RTClient } from "@/app/rt-client.mjs";
import {
  getLoadout,
  removePlayerIdentity,
  setPlayerIdentity,
} from "@/game-fortnite/overlay-state.mjs";
import { devDebug } from "@/util/dev.mjs";

function listenRTEvents(client) {
  client.on("open", () => {
    // Immediately try to self-identify.
    client.identity = {
      lol: `swan ${Math.floor(Math.random() * 100)}`,
      player_state: {
        loadout: getLoadout(),
      },
    };

    // set channel to chat room name
    client.channel = "fortnite-testing";
  });

  client.on("identity", (identity) => {
    devDebug("rt identity", identity);
    setPlayerIdentity(identity);
  });

  client.on("channel_state", (channelState) => {
    devDebug("rt channel_state", channelState);
    for (const identity of channelState.ids || []) {
      if (!identity) continue;
      setPlayerIdentity(identity);
    }
  });

  client.on("peer_disconnect", (disconnect) => {
    devDebug("rt peer_disconnect", disconnect);
    removePlayerIdentity(disconnect.id);
  });
}

export default function fetchData() {
  rtState.client = new RTClient();
  listenRTEvents(rtState.client);
  // Note: there is no disconnect, because it is assumed that
  // there isn't a use case for navigating away from an overlay route
}
